import { IRichmenu } from "@types";
import { create } from "zustand";

type IRichmenuStore = {
  richmenu: IRichmenu | null;
  isOpenRichmenu: boolean;
  setupRichmenu: (richmenu: IRichmenu | null) => void;
  toggleRichmenu: () => void;
};

export const useRichmenuStore = create<IRichmenuStore>((set) => {
  return {
    richmenu: null,
    isOpenRichmenu: false,
    setupRichmenu: (richmenu: IRichmenu | null) => {
      return set(() => ({
        richmenu: richmenu,
      }));
    },
    toggleRichmenu: () =>
      set((state) => ({
        isOpenRichmenu: !state.isOpenRichmenu,
      })),
  };
});

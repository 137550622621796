import React, { useEffect, useRef, useState } from "react";
import { useShareLocation } from "@store/shareLocationStore";
import { AnimatePresence, motion } from "framer-motion";
import Mappin from "@assets/mappin.png";
import GoogleMap, { onGoogleApiLoadedProps } from "google-maps-react-markers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/pro-solid-svg-icons";
import { GOOGLE_MAPS_APIKEY, DEFAULT_LATLNG } from "@constants";

export type PlaceProps = {
  name: string;
  geometry: {
    location: { lat: () => number; lng: () => number };
  };
};

type ShareLocationProps = {
  onShareLocation: (location: {
    lat: number;
    lng: number;
    detail?: string;
  }) => void;
};

export const ShareLocation = ({ onShareLocation }: ShareLocationProps) => {
  const mapRef = useRef(null);
  const mapsRef = useRef<any>(null);
  const mapContainerRef = useRef<HTMLDivElement>(null);
  const { isOpen, currentLocation, setIsOpen, setCurrentLocation } =
    useShareLocation();
  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      setCurrentLocation({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      });
    });
  }, []);

  useEffect(() => {
    // close modal when press esc
    const handleEscape = (event: KeyboardEvent) => {
      if (event.key === "Escape" && isOpen) {
        setIsOpen(false);
      }
    };
    document.addEventListener("keydown", handleEscape);
    // clean up
    return () => {
      document.removeEventListener("keydown", handleEscape);
    };
  }, [isOpen]);

  const onGoogleApiLoaded = ({ map, maps }: onGoogleApiLoadedProps) => {
    mapRef.current = map;
    mapsRef.current = maps;
    const searchBox = new maps.places.SearchBox(
      document.getElementById("ggmap-search")
    );
    searchBox?.addListener("places_changed", () => {
      const places = searchBox.getPlaces();
      if (places.length > 0) {
        const place = JSON.parse(JSON.stringify(places[0]));
        setCurrentLocation({
          lat: place.geometry.location.lat,
          lng: place.geometry.location.lng,
        });
        map.setCenter({
          lat: place.geometry.location.lat,
          lng: place.geometry.location.lng,
        });
      }
    });
  };

  return (
    <>
      <button onClick={() => setIsOpen(!isOpen)}>Click</button>
      <AnimatePresence mode="popLayout">
        {isOpen && (
          <motion.div
            className="relative z-10"
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
          >
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2, easings: "ver(--ease-out-quart)" }}
              className="fixed inset-0 bg-gray-500 bg-opacity-75"
              aria-hidden="true"
            />

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <motion.div
                  ref={mapContainerRef}
                  initial={{ opacity: 0, scale: 0.96 }}
                  animate={{ opacity: 1, scale: 1, y: 0 }}
                  exit={{ opacity: 0, scale: 0.96 }}
                  transition={{
                    duration: 0.2,
                    easings: "ver(--ease-out-quart)",
                  }}
                  className="relative w-full origin-bottom transform overflow-hidden rounded-lg bg-white text-left shadow-xl sm:my-8 sm:w-full sm:max-w-lg"
                >
                  <div className="bg-white">
                    <div className="flex items-center justify-between bg-[#37474F] px-[20px] py-[15px] text-white">
                      <p>Shared Your Location</p>
                      <button onClick={() => setIsOpen(false)}>
                        <FontAwesomeIcon icon={faX} />
                      </button>
                    </div>
                    <div className="relative h-[400px] w-full">
                      <GoogleMap
                        libraries={["places"]}
                        apiKey={GOOGLE_MAPS_APIKEY}
                        defaultCenter={{
                          lat: currentLocation?.lat || DEFAULT_LATLNG.lat,
                          lng: currentLocation?.lng || DEFAULT_LATLNG.lng,
                        }}
                        options={{
                          streetViewControl: false,
                          mapTypeControl: false,
                        }}
                        onGoogleApiLoaded={onGoogleApiLoaded}
                        defaultZoom={18}
                        onChange={(map) => {
                          setCurrentLocation({
                            lat: map.center[1] || DEFAULT_LATLNG.lat,
                            lng: map.center[0] || DEFAULT_LATLNG.lng,
                          });
                        }}
                      ></GoogleMap>
                      <img
                        src={Mappin}
                        className="absolute left-[50%] top-[50%] h-[32px] w-[32px]"
                        style={{
                          transform: "translate(-50%, -50%)",
                        }}
                      />
                      <div className="absolute left-[10px] top-[10px] w-1/2">
                        <input
                          id="ggmap-search"
                          type="text"
                          className="block w-full rounded-lg border border-gray-300 bg-white p-2.5 text-sm text-gray-900"
                          placeholder="Enter location name"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="w-full bg-gray-50 px-4 py-3 sm:flex sm:px-6">
                    <button
                      type="button"
                      onClick={() => {
                        let geocoder = null;
                        if (mapsRef.current) {
                          geocoder = new mapsRef.current.Geocoder();
                        }
                        if (geocoder) {
                          geocoder.geocode(
                            { location: currentLocation },
                            (results: any[], status: string) => {
                              if (status === "OK") {
                                if (results[0]) {
                                  const address = results[0].formatted_address;
                                  if (currentLocation) {
                                    onShareLocation({
                                      lat: currentLocation.lat,
                                      lng: currentLocation.lng,
                                      detail: address,
                                    });
                                  }
                                } else {
                                  if (currentLocation) {
                                    onShareLocation({
                                      lat: currentLocation.lat,
                                      lng: currentLocation.lng,
                                      detail: "",
                                    });
                                  }
                                }
                              } else {
                                console.error(
                                  "Geocoder failed due to: " + status
                                );
                                if (currentLocation) {
                                  onShareLocation({
                                    lat: currentLocation.lat,
                                    lng: currentLocation.lng,
                                    detail: "",
                                  });
                                }
                              }
                            }
                          );
                        }

                        setIsOpen(false);
                      }}
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 "
                    >
                      Share Location
                    </button>
                  </div>
                </motion.div>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

import { client, OG_SERVER_ENDPOINT } from "@api";
import { ICombinedMessage } from "@types";
import axios from "axios";

export const fetchMessages = async () => {
  const { data }: { data: ICombinedMessage[] } = await client.get("/messages");
  return data;
};

export const sendMessage = async (
  message: Omit<ICombinedMessage, "status">
) => {
  const { data }: { data: ICombinedMessage } = await client.post(
    "/messages",
    message
  );
  return data;
};

export const getOgData = async (url: string) => {
  const {
    result,
  }: {
    result: {
      title: string;
      description: string;
      image: string;
    };
  } = (await axios.get(`${OG_SERVER_ENDPOINT}?url=${url}`)).data;
  return result;
};

import { render } from "@external_libs/flex_render";
import { IFlex, IMessageAction } from "@types";
import { createDataAttr } from "@utils";
import { useEffect, useRef, useState } from "react";

type FlexMessageProps = {
  message: IFlex;
  onSendAction: (action: IMessageAction) => void;
};

export const FlexMessage = ({ message, onSendAction }: FlexMessageProps) => {
  const initialized = useRef(false);
  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      const result = document.querySelectorAll(
        `[data-ams-message-id="flex-action-${message.id}"]`
      );

      result.forEach((element) => {
        element.addEventListener("click", (event) => {
          try {
            const actionStr = element.getAttribute("data-ams-action");

            if (!actionStr) return;
            const action = JSON.parse(decodeURIComponent(actionStr));
            onSendAction(action);
            return;
          } catch (error) {
            console.error("Error when parsing action", error);
          }
        });
      });
    }
  }, []);

  const result =
    render(message.contents, message.id) ?? `<div>Empty Flex Message</div>`;
  return (
    <div
      className="w-[300px]"
      {...createDataAttr("flex-message")}
      dangerouslySetInnerHTML={{ __html: result }}
    />
  );
};

import React, { useMemo } from "react";
import { createDataAttr } from "@utils";
import { LinkItUrl } from "react-linkify-it";
import { useQuery } from "@tanstack/react-query";
import { getOgData } from "@api/message";

export type ITextMessageProps = {
  message: string;
  linkTarget: string;
};

export const TextMessage = ({
  message,
  linkTarget,
}: React.PropsWithChildren<ITextMessageProps>) => {
  const isLink = useMemo(() => {
    if (!message) return false;
    try {
      const url = new URL(message);
      if (!url.host) {
        return false;
      }
      return true;
    } catch (error) {
      return false;
    }
  }, [message]);

  if (isLink) {
    return (
      <LinkItUrl {...createDataAttr("text-message")} className="text-blue-700">
        <p
          style={{
            wordBreak: "break-word",
          }}
          className="text-message group 
     inline-block w-fit 
     whitespace-pre-wrap 
     break-words rounded-[15px] bg-white px-[12px] py-[10px] text-black"
        >
          <OgUrl url={message} linkTarget={linkTarget} />
        </p>
      </LinkItUrl>
    );
  }

  return (
    <span {...createDataAttr("text-message")}>
      <p
        style={{
          wordBreak: "break-word",
        }}
        className="text-message group 
        inline-block w-fit 
        whitespace-pre-wrap 
        break-words rounded-[15px] bg-white px-[12px] py-[10px] text-black"
      >
        {message}
      </p>
    </span>
  );
};

const OgUrl = ({
  url,
  linkTarget = "_blank",
}: {
  url: string;
  linkTarget?: string;
}) => {
  const { data } = useQuery(["og", url], () => getOgData(url), {
    retry: 0,
    refetchOnWindowFocus: false,
  });
  const _renderContent = () => {
    if (!data) return null;
    return (
      <div className=" flex gap-x-[8px]">
        <div
          style={{
            height: "min-content",
          }}
          className="flex-1 border-l-[3px] border-solid border-primary pl-[4px]"
        >
          <span className="line-clamp-2" title={data.title}>
            {data.title}
          </span>

          <p
            className="overflow-hidden  text-ellipsis leading-5 !text-[#BDBDBD] line-clamp-2"
            title={data.description}
          >
            {data.description}
          </p>
        </div>
        {data.image ? (
          <div className="h-[54px] w-[54px] self-end rounded-lg border border-solid border-black p-1">
            <img
              className="h-full w-full object-contain"
              src={data.image}
              alt="og"
            />
          </div>
        ) : null}
      </div>
    );
  };
  return (
    <div>
      <a
        className="text-blue-700 line-clamp-1"
        href={url}
        target={linkTarget}
        rel="noreferrer"
      >
        {url}
      </a>
      {_renderContent()}
    </div>
  );
};

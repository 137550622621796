import { getRichmenu } from "@api/richmenu";
import { richmenuRefetcher } from "@libs/richmenuRefetcher";
import { useAppStore } from "@store/appStore";
import { useRichmenuStore } from "@store/richmenuStore";
import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { shallow } from "zustand/shallow";

export const useChat = () => {
  const { richmenu, setupRichmenu, toggleRichmenu } = useRichmenuStore();
  const { token, settings } = useAppStore(
    (state) => ({
      token: state.token,
      settings: state.settings,
    }),
    shallow
  );
  const { isLoading, data, isError, refetch } = useQuery({
    queryKey: ["richmenu", token],
    queryFn: () => getRichmenu(token),
    refetchOnWindowFocus: false,
    retry: false,
    enabled: !!token,
  });

  if (!richmenuRefetcher.refetch) {
    richmenuRefetcher.refetch = refetch;
  }

  useEffect(() => {
    // if data exist set store
    if (!data) return;
    setupRichmenu(data);
    // if selected true mean open richmenu by default
    if (data.selected) {
      toggleRichmenu();
      return;
    }
  }, [data]);

  useEffect(() => {
    // if error set error message
    if (isError) {
      setupRichmenu(null);
    }
  }, [isError]);

  return {
    settings,
    richmenu,
    richmenuState: {
      isLoading,
      isError,
      refetch,
    },
    isShowHeader: false,
  };
};

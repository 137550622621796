import { EMessageStatus, IImageMessage } from "@types";
import { Img } from "react-image";
import { ImageLoader } from "./ImageLoader";
import { faCircleExclamation } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createDataAttr } from "@utils";

export type IImageMessageProps = {
  message: IImageMessage;
};

export const ImageMessage = ({ message }: IImageMessageProps) => {
  if (message.status === EMessageStatus.SENDING) {
    return (
      <div {...createDataAttr("image-message-sending")}>
        <div className="w-[400px] rounded-[10px] object-contain">
          <ImageLoader />
        </div>
      </div>
    );
  }
  // error
  if (message.status === EMessageStatus.ERROR) {
    return (
      <div {...createDataAttr("image-message-error")}>
        <div className="flex h-[50px] w-[240px] items-center justify-center rounded-[10px] bg-[#dbdbdb] object-cover">
          <FontAwesomeIcon icon={faCircleExclamation} fontSize={24} />
        </div>
      </div>
    );
  }
  return (
    <div {...createDataAttr("image-message")}>
      <Img
        className="w-[400px] rounded-[10px] object-contain"
        src={message.previewImageUrl}
        loader={<ImageLoader />}
        alt="image"
      />
    </div>
  );
};

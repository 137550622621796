import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPaperPlane,
  faPaperclipVertical,
} from "@fortawesome/pro-regular-svg-icons";
import { TEXT_AREA_MAX_HEIGHT } from "@constants";

export type ITextComposerProps = {
  acceptFileTypes: string[];
  textAreaRef: React.RefObject<HTMLTextAreaElement>;
  fileInputRef: React.RefObject<HTMLInputElement>;
  message: string;
  onType: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onSend: (msg?: string | undefined) => Promise<void>;
  onInputFileChange: (e: React.ChangeEvent<HTMLInputElement>) => Promise<void>;
};

export const TextComposer = ({
  acceptFileTypes = [],
  textAreaRef,
  fileInputRef,
  message,
  onType,
  onSend,
  onInputFileChange,
}: React.PropsWithChildren<ITextComposerProps>) => {
  return (
    <>
      <textarea
        ref={textAreaRef}
        value={message}
        rows={1}
        onKeyDown={(e) => {
          const keyCode = e.which || e.keyCode;
          // enter but no shift
          if (keyCode === 13 && !e.shiftKey) {
            if (message.length > 0) {
              // if (isMobile) {
              //   return;
              // }
              onSend();
              if (textAreaRef.current) {
                textAreaRef.current.style.height = "40px";
              }
            }
            e.preventDefault();
          }
        }}
        placeholder="type something"
        className={`max-h-[${TEXT_AREA_MAX_HEIGHT}px] flex-1 resize-none overflow-y-hidden bg-[transparent] py-[8px] pr-[80px] text-[#222222] outline-none`}
        onChange={onType}
      />
      <div className="absolute bottom-[12px] right-[30px] flex gap-x-[8px]">
        {/* File input */}
        <input
          type="file"
          accept={acceptFileTypes.join(",")}
          className="hidden"
          ref={fileInputRef}
          onChange={onInputFileChange}
        />
        <button
          onClick={() => {
            if (fileInputRef.current) {
              fileInputRef.current.click();
            }
          }}
          className=""
        >
          <FontAwesomeIcon
            icon={faPaperclipVertical}
            fontSize={20}
            color="rgba(80,35,20,var(--tw-text-opacity))"
            className="text-[20px]"
          />
        </button>
        {message ? (
          <button
            onClick={() => {
              onSend();
              if (textAreaRef.current) {
                textAreaRef.current.style.height = "40px";
              }
            }}
          >
            <FontAwesomeIcon
              icon={faPaperPlane}
              fontSize={20}
              color="rgba(80,35,20,var(--tw-text-opacity))"
              className="text-[20px]"
            />
          </button>
        ) : null}
      </div>
    </>
  );
};

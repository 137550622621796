import React from "react";
import { Chat } from "@containers/chat";
import { useApp } from "@useApp";
import { Loading } from "@shares/components";
import { LOADING_TEXT } from "@constants/wording";
import "@splidejs/splide/dist/css/themes/splide-skyblue.min.css";
import "flex-render/css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import "@splidejs/react-splide/css";
// Create a client
const queryClient = new QueryClient();
function App() {
  const { isInit, loading, errorMessage } = useApp();

  return (
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <Loading
          active={loading}
          spinner
          text={LOADING_TEXT}
          className="h-[100dvh]"
        >
          {errorMessage && (
            <h1 className="absolute left-[50%] top-[50%] translate-x-[-50%] whitespace-nowrap text-3xl">
              {errorMessage}
            </h1>
          )}
          {isInit && <Chat />}
        </Loading>
      </QueryClientProvider>
      {/* disabled type check here */}
      {/* @ts-ignore:next-line */}
      <div className="hidden">version{APP_VERSION}</div>
    </React.StrictMode>
  );
}

export default App;

import { DEFAULT_TARGET } from "@constants";
import { useShareLocation } from "@store/shareLocationStore";
import { EMessageActionType, IButton, IMessageAction } from "@types";
import { createDataAttr } from "@utils";
import { shallow } from "zustand/shallow";
interface IOptionMessageProps {
  message: IButton;
  linkTarget?: string;
  onSendAction: (action: IMessageAction) => void;
}

interface IButtonActionProps {
  button: IMessageAction;
  linkTarget?: string;
  onSendAction: (action: IMessageAction) => void;
}

const ButtonAction = ({
  button,
  linkTarget = DEFAULT_TARGET,
  onSendAction,
}: IButtonActionProps) => {
  const { setIsOpen } = useShareLocation((state) => {
    return state;
  }, shallow);
  if (
    button.type === EMessageActionType.DATE_TIME_PICKER ||
    button.type === EMessageActionType.CAMERA_ACTION
  ) {
    <button className="cursor-pointer py-4 text-blue-700">
      {button.label}
    </button>;
  }
  // link action
  if (button.type === EMessageActionType.URI_ACTION) {
    return (
      <a
        className="cursor-pointer py-4 text-center text-blue-700"
        href={button.uri}
        target={linkTarget}
      >
        {button.label}
      </a>
    );
  }
  // call action
  if (button.type === EMessageActionType.CALL_ACTION) {
    return (
      <a
        className="cursor-pointer py-4 text-center text-blue-700"
        href={`tel:${button.phoneNo}`}
      >
        {button.label}
      </a>
    );
  }
  // location action
  if (button.type === EMessageActionType.LOCATION_ACTION) {
    return (
      <button
        className="cursor-pointer py-4 text-center text-blue-700"
        onClick={() => setIsOpen(true)}
      >
        {button.label}
      </button>
    );
  }
  return (
    <button
      className="cursor-pointer overflow-hidden text-ellipsis px-3 py-4 text-blue-700"
      onClick={() => onSendAction(button)}
    >
      {button.label}
    </button>
  );
};

export const OptionsMessage = ({
  message,
  linkTarget = DEFAULT_TARGET,
  onSendAction,
}: IOptionMessageProps) => {
  return (
    <div className="flex items-center" {...createDataAttr("option-message")}>
      <div className="w-full min-w-[240px] max-w-md rounded-lg bg-white shadow-lg">
        <p className="mb-4 whitespace-pre-wrap break-words p-6 text-gray-600">
          {message.text}
        </p>
        <hr className="my-2 border-t" />
        <div>
          {message.actions &&
            message.actions.map((button: IMessageAction, index: number) => (
              <div
                title={button.label}
                className="mx-auto flex flex-col"
                key={button.id}
                {...createDataAttr("option-button")}
              >
                <ButtonAction
                  button={button}
                  onSendAction={onSendAction}
                  linkTarget={linkTarget}
                />
                {index !== message.actions.length - 1 && (
                  <hr className="my-2 border-t" />
                )}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

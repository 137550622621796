import React from "react";
import { createDataAttr } from "@utils";
import { GOOGLE_MAPS_APIKEY, DEFAULT_LATLNG } from "@constants";
import GoogleMap, { onGoogleApiLoadedProps } from "google-maps-react-markers";
import Mappin from "@assets/mappin.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/pro-light-svg-icons";

type ILocationMessageProps = {
  currentLocation: {
    lat: number;
    lng: number;
    description: string;
  } | null;
};

export const LocationMessage = ({ currentLocation }: ILocationMessageProps) => {
  const onGoogleApiLoaded = ({ map, maps }: onGoogleApiLoadedProps) => {
    map.setOptions({
      draggable: false,
      zoomControl: false,
      scrollwheel: false,
      disableDoubleClickZoom: false,
      fullscreenControl: false,
    });
  };
  return (
    <div
      className="location-message relative w-[400px] overflow-hidden rounded-t-lg bg-white p-3"
      {...createDataAttr("location-message")}
    >
      <div className="h-[400px]">
        <GoogleMap
          apiKey={GOOGLE_MAPS_APIKEY}
          defaultCenter={{
            lat: currentLocation?.lat || DEFAULT_LATLNG.lat,
            lng: currentLocation?.lng || DEFAULT_LATLNG.lng,
          }}
          options={{
            streetViewControl: false,
            mapTypeControl: false,
          }}
          onGoogleApiLoaded={onGoogleApiLoaded}
          defaultZoom={18}
        />
      </div>

      {currentLocation?.description && (
        <div
          className="bg-[#5C6BC0] p-2 text-sm uppercase"
          style={{
            wordBreak: "break-word",
          }}
        >
          <span className="text-white">
            <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-1" />
            <span>{currentLocation.description}</span>
          </span>
        </div>
      )}

      <img
        src={Mappin}
        className="absolute left-[50%] top-[50%] h-[32px] w-[32px]"
        style={{
          transform: "translate(-50%, -50%)",
        }}
      />
    </div>
  );
};

import { useEffect, useRef } from "react";
import { useChatContext } from "..";
import { useActor, useSelector } from "@xstate/react";
import { IChatMachineBoxContext } from "../machine";
import { Livechat } from "@api";
import { IInCommingMessage, IMessageAction } from "@types";
import { useAppStore } from "@store/appStore";
import { shallow } from "zustand/shallow";
import { generateActionMessage } from "@fns";
import { BOTTOM_SCROLL_THRESHOLD } from "@constants";
import { useRichmenuStore } from "@store/richmenuStore";
import { useChat } from "../useChat";

const selectMessage = ({ context }: { context: IChatMachineBoxContext }) =>
  context.messages;
const selectFirstItemIndex = ({
  context,
}: {
  context: IChatMachineBoxContext;
}) => context.firstItemIndex;

export const useMessageBox = () => {
  const wrapRef = useRef<HTMLDivElement>(null);

  const { richmenuState } = useChat();
  const machineService = useChatContext();
  const { user, token, room } = useAppStore((state) => {
    return {
      user: state.user,
      token: state.token,
      room: state.room,
    };
  }, shallow);
  const [state] = useActor(machineService.chatService);
  const { send } = machineService.chatService;
  const messages = useSelector(machineService.chatService, selectMessage);
  const firstItemIndex = useSelector(
    machineService.chatService,
    selectFirstItemIndex
  );

  useEffect(() => {
    let ignore = false;
    Livechat.onMessage((message) => {
      try {
        const incomming = message as unknown as IInCommingMessage;
        const payload = JSON.parse(incomming.msg);
        if (payload.type === "richmenuChanged") {
          richmenuState.refetch();
        } else {
          if (!ignore) {
            send("NEW_INCOMING_MESSAGE", {
              payload: {
                uid: user._id,
                newMessage: incomming,
              },
            });
          }
        }
      } catch (error) {}
    });
  }, []);

  return {
    wrapRef,
    firstItemIndex,
    isFetchFailed: state.matches("fetchMoreMessagesFailed"),
    isFirstFetching: state.matches("firstFetchingMessages"),
    isUserSendMessage: state.matches("sendingMessage"),
    isLoadingMore: state.matches("fetchingMoreMessages"),
    messages,
    hasMore: state.context.hasMore,
    onSendAction: async (action: IMessageAction) => {
      try {
        const outgoing = await generateActionMessage(action, room, token);
        // if null
        if (!outgoing) return;
        send("SEND_MESSAGE", outgoing);
      } catch (error) {
        console.error("error create message action ", error);
      }
    },
    onLoadMoreMessages: () => {
      send("LOAD_MORE_MESSAGES");
    },
  };
};

import { IRichmenu } from "@types";
import { create } from "zustand";

type IRichmenuStore = {
  isOpen: boolean;
  currentLocation: {
    lat: number;
    lng: number;
  } | null;
  setCurrentLocation: (location: { lat: number; lng: number }) => void;
  setIsOpen: (isOpen: boolean) => void;
};

export const useShareLocation = create<IRichmenuStore>((set) => {
  return {
    isOpen: false,
    currentLocation: null,
    setIsOpen: (value: boolean) => {
      return set(() => ({
        isOpen: value,
      }));
    },
    setCurrentLocation: (location) => {
      return set(() => ({
        currentLocation: location,
      }));
    },
  };
});
